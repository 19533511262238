import { createAsyncThunk } from "@reduxjs/toolkit";
import to from "await-to-js";
import { AxiosError } from "axios";

import { TReducerError } from "@/types/app";

import handlingError from "@/utils/handlingError";

import {
  IAPIDeletePlan,
  IAPIGetAppInterface,
  IAPIGetBusinessHours,
  IAPIGetCGU,
  IAPIGetPlans,
  IAPIPatchAppInterface,
  IAPIPatchCGU,
  IAPIPatchEthnicities,
  IAPIPatchPlan,
  IAPIPostAppInterface,
  IAPIPostCGU,
  IAPIPostEthnicities,
  IAPIPostPlan,
  IAPIResponseGetEthnicities,
  IApiGet,
  TAppInterfaceSettings,
  TTimeSlot,
} from "@/features/backoffice/types";

import {
  createEthnicity,
  createPlans,
  deleteEthnicity,
  editPlan,
  getCGU,
  getEthnicities,
  getInterfaceSettings,
  getPlans,
  getTimeSlots,
  initCGU,
  initInterfaceSettings,
  togglePlanActiveness,
  updateCGU,
  updateEthnicity,
  updateInterfaceSettings,
  updateTimeSlots,
} from "@/features/backoffice/api";

import { TPlanInitialValues } from "@/features/backoffice/containers/Settings/Plans/Plans.container";
import {
  createPaidService,
  deactivatePaidService,
  getPaidServices,
  togglePaidServiceActiveness,
  updatePaidService,
} from "@/features/backoffice/api/settings/paidServices.api";
import {
  IAPIDeletePaidServices,
  IAPIGetPaidServices,
  IAPIPatchPaidServices,
  IAPIPostPaidServices,
} from "@/features/backoffice/types/interfaces/api/paidServices";
import { TPaidServicesInitialValues } from "@/features/backoffice/containers/Settings/PaidServices/PaidServices.container";
import {
  IAPIPatchBodyShape,
  IAPIPostBodyShape,
  IAPIResponseGetBodyShape,
} from "@/features/backoffice/types/interfaces/api/bodyShape";
import {
  createBodyShape,
  deleteBodyShape,
  getBodyShapes,
  updateBodyShape,
} from "@/features/backoffice/api/settings/bodyShape.api";

export const getBusinessHoursAsync = createAsyncThunk<
  IAPIGetBusinessHours,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("businessHours/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetBusinessHours, AxiosError<any>>(
    getTimeSlots()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateBusinessHoursAsync = createAsyncThunk<
  IAPIGetBusinessHours,
  { data: Array<TTimeSlot> },
  {
    rejectValue: TReducerError;
  }
>("businessHours/update", async (params, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetBusinessHours, AxiosError<any>>(
    updateTimeSlots(params)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getEthnicitiesAsync = createAsyncThunk<
  IAPIResponseGetEthnicities,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("ethnicities/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetEthnicities, AxiosError<any>>(
    getEthnicities()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createEthnicitiesAsync = createAsyncThunk<
  IAPIPostEthnicities,
  { name: string },
  {
    rejectValue: TReducerError;
  }
>("ethnicities/create", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostEthnicities, AxiosError<any>>(
    createEthnicity(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateEthnicitiesAsync = createAsyncThunk<
  IAPIPatchEthnicities,
  { _id: string; name: string },
  {
    rejectValue: TReducerError;
  }
>("ethnicities/update", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchEthnicities, AxiosError<any>>(
    updateEthnicity(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteEthnicitiesAsync = createAsyncThunk<
  IAPIPatchEthnicities,
  { _id: string },
  {
    rejectValue: TReducerError;
  }
>("ethnicities/delete", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchEthnicities, AxiosError<any>>(
    deleteEthnicity(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getBodyShapesAsync = createAsyncThunk<
  IAPIResponseGetBodyShape,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("bodyShape/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseGetBodyShape, AxiosError<any>>(
    getBodyShapes()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createBodyShapeAsync = createAsyncThunk<
  IAPIPostBodyShape,
  { name: string },
  {
    rejectValue: TReducerError;
  }
>("bodyShape/create", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostBodyShape, AxiosError<any>>(
    createBodyShape(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateBodyShapeAsync = createAsyncThunk<
  IAPIPatchBodyShape,
  { _id: string; name: string },
  {
    rejectValue: TReducerError;
  }
>("bodyShape/update", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchBodyShape, AxiosError<any>>(
    updateBodyShape(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteBodyShapeAsync = createAsyncThunk<
  IAPIPatchBodyShape,
  { _id: string },
  {
    rejectValue: TReducerError;
  }
>("bodyShape/delete", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchBodyShape, AxiosError<any>>(
    deleteBodyShape(param)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getPaidServicesAsync = createAsyncThunk<
  IAPIGetPaidServices,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("paidServices/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetPaidServices, AxiosError<any>>(
    getPaidServices()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createPaidServiceAsync = createAsyncThunk<
  IAPIPostPaidServices,
  TPaidServicesInitialValues,
  {
    rejectValue: TReducerError;
  }
>("paidServices/create", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostPaidServices, AxiosError<any>>(
    createPaidService(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updatePaidServiceAsync = createAsyncThunk<
  IAPIPostPaidServices,
  { datas: Partial<TPaidServicesInitialValues> },
  {
    rejectValue: TReducerError;
  }
>("paidServices/update", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchPaidServices, AxiosError<any>>(
    updatePaidService(values.datas._id!, values.datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const togglePaidServiceAsync = createAsyncThunk<
  IAPIDeletePaidServices,
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("paidServices/toggleActiveness", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIDeletePaidServices, AxiosError<any>>(
    togglePaidServiceActiveness(values.id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getPlansAsync = createAsyncThunk<
  IAPIGetPlans,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("plans/get", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetPlans, AxiosError<any>>(getPlans());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createPlansAsync = createAsyncThunk<
  IAPIPostPlan,
  TPlanInitialValues,
  {
    rejectValue: TReducerError;
  }
>("plans/create", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostPlan, AxiosError<any>>(
    createPlans(values)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updatePlansAsync = createAsyncThunk<
  IApiGet<IAPIPatchPlan>,
  { datas: Partial<TPlanInitialValues> },
  {
    rejectValue: TReducerError;
  }
>("plans/update", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IApiGet<IAPIPatchPlan>, AxiosError<any>>(
    editPlan(values.datas._id!, values.datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const togglePlanActivenessAsync = createAsyncThunk<
  IAPIDeletePlan,
  { id: string },
  {
    rejectValue: TReducerError;
  }
>("plans/toggleActivenessPlan", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIDeletePlan, AxiosError<any>>(
    togglePlanActiveness(values.id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getAppSettingsAsync = createAsyncThunk<
  IAPIGetAppInterface,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("appSettings/get", async (_, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetAppInterface, AxiosError<any>>(
    getInterfaceSettings()
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const initAppSettingsAsync = createAsyncThunk<
  IAPIPostAppInterface,
  TAppInterfaceSettings,
  {
    rejectValue: TReducerError;
  }
>("appSettings/init", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostAppInterface, AxiosError<any>>(
    initInterfaceSettings(datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateAppSettingsAsync = createAsyncThunk<
  IAPIPatchAppInterface,
  TAppInterfaceSettings,
  {
    rejectValue: TReducerError;
  }
>("appSettings/update", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchAppInterface, AxiosError<any>>(
    updateInterfaceSettings({
      ...datas,
      maintenance: {
        website: Boolean(datas.maintenance.website),
        suspendModeration: Boolean(datas.maintenance.suspendModeration),
      },
    })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getCGUAsync = createAsyncThunk<
  IAPIGetCGU,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("cgu/get", async (_, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetCGU, AxiosError<any>>(getCGU());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const initCGUAsync = createAsyncThunk<
  IAPIPostCGU,
  string,
  {
    rejectValue: TReducerError;
  }
>("cgu/init", async (content, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostCGU, AxiosError<any>>(
    initCGU(content)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateCGUAsync = createAsyncThunk<
  IAPIPatchCGU,
  IAPIPatchCGU,
  {
    rejectValue: TReducerError;
  }
>("cgu/update", async (param, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchCGU, AxiosError<any>>(
    updateCGU({ id: param._id, content: param.content })
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

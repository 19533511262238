import { createSlice } from "@reduxjs/toolkit";
import asyncActions from "./imamSpeakers.asyncActions";

import { IImamSpeakersInitialState } from "./imamSpeakers.interfaces";

const initialState: IImamSpeakersInitialState = {
  listImamSpeakers: null,
};

export const imamSpeakersSlice = createSlice({
  name: "imamSpeakers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    asyncActions(builder);
  },
});

export const imamSpeakersSliceActions = imamSpeakersSlice.actions;

export default imamSpeakersSlice.reducer;

import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectApp = (state: TRootState) => state.app;

export const selectItemsPerPage = createSelector(
  [selectApp],
  (app) => app.itemsPerPage
);

export const selectAvailability = createSelector(
  [selectApp],
  (app) => app.isAvailable
);

import { createTheme, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: string;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions | undefined;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    // short: true;
    // long: true;
  }
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const { palette } = createTheme();

export const muiCustomTheme = createTheme({
  palette: {
    primary: {
      main: "#73103E",
      // dark:,
      // light:,
      //contrastText:,
    },
    secondary: {
      main: "#FEF8EC",
      // dark:,
      // light:,
      //contrastText:,
    },
    tertiary: palette.augmentColor({
      color: {
        main: "#DEB86B",
        // dark:,
        // light:,
        contrastText: "#ffffff",
      },
    }),
    error: {
      main: "#872D2D",
      // dark:,
      // light:,
      //contrastText:,
    },
    warning: {
      main: "#F5B544",
      // dark:,
      // light:,
      //contrastText:,
    },
    // info: {
    //   main: '#73103E',
    //   dark:,
    //   light:,
    //   contrastText:,
    // },
    success: {
      main: "#75D873",
      // dark:,
      // light:,
      //contrastText:,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "'Lato', sans-serif",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "40px",
          marginBottom: 20,
          "& fieldset": {
            borderRadius: 10,
            borderColor: "#e6e8ec",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: 10,
          borderColor: "#e6e8ec",
          "& fieldset": {
            borderRadius: 10,
            borderColor: "#e6e8ec",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "50px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: 5,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 5,
            color: "#DEB86B",
            borderColor: "#DEB86B",
          },
        },
      ],
    },
  },
});

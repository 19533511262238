import { TReducerError } from "@/types/app";
import { createSlice } from "@reduxjs/toolkit";

import adminAsyncActions from "./admin.asyncActions";

import { IAdminsInitialState } from "./admin.interfaces";

export const errorInitialValue: TReducerError = {
  message: "",
  value: null,
};

const initialState: IAdminsInitialState = {
  listAdmins: null,
  loading: "idle",
  errors: {
    message: "",
    value: null,
  },
};

export const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {},
  extraReducers(builder) {
    adminAsyncActions(builder);
  },
});

export const adminSliceActions = adminSlice.actions;

export default adminSlice.reducer;

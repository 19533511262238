import * as React from "react";

import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "../store";
import { theme } from "../theme";

import { SuspenseFallback } from "@/components/SuspenseFallback/SuspenseFallback";
import { muiCustomTheme } from "@/theme/muiCustomTheme";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense fallback={<SuspenseFallback />}>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <MuiThemeProvider theme={muiCustomTheme}>
              <ThemeProvider theme={theme}>
                <Router>{children}</Router>
              </ThemeProvider>
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.Suspense>
  );
};

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { ISettingsInitialState } from "./settings.interfaces";

import { TEthnie } from "@/features/backoffice/containers/Settings/Ethnicities/Ethnicities.container";
import {
  IAPIGetBusinessHours,
  IAPIGetCGU,
  IAPIPatchCGU,
  IApiGet,
  TAppInterfaceSettings,
} from "@/features/backoffice/types";
import { Toastify } from "@/lib/toast";
import {
  createBodyShapeAsync,
  createEthnicitiesAsync,
  createPaidServiceAsync,
  createPlansAsync,
  deleteBodyShapeAsync,
  deleteEthnicitiesAsync,
  getAppSettingsAsync,
  getBodyShapesAsync,
  getBusinessHoursAsync,
  getCGUAsync,
  getEthnicitiesAsync,
  getPaidServicesAsync,
  getPlansAsync,
  initAppSettingsAsync,
  initCGUAsync,
  togglePaidServiceAsync,
  togglePlanActivenessAsync,
  updateAppSettingsAsync,
  updateBodyShapeAsync,
  updateBusinessHoursAsync,
  updateCGUAsync,
  updateEthnicitiesAsync,
  updatePaidServiceAsync,
  updatePlansAsync,
} from "./settings.services";
import { createBodyShape } from "@/features/backoffice/api/settings/bodyShape.api";
import { TBodyShapes } from "@/features/backoffice/containers/Settings/BodyShapes/Bodyshapes.container";

const adminAsyncActions = (
  builder: ActionReducerMapBuilder<ISettingsInitialState>
) => {
  builder.addCase(getEthnicitiesAsync.fulfilled, (state, action) => {
    if (!action.payload) return;

    state.ethnicities = (action.payload as any).data;
  });

  builder.addCase(createEthnicitiesAsync.fulfilled, (state, action) => {
    if (state.ethnicities) {
      state.ethnicities.result.push(action.payload.data!);
      state.ethnicities.count++;
    }
  });

  builder.addCase(updateEthnicitiesAsync.fulfilled, (state, action) => {
    const payload = action.payload as unknown as { data: TEthnie };
    if (state.ethnicities) {
      const index = state.ethnicities.result.findIndex(
        (ethnie) => ethnie._id === payload.data._id
      );

      state.ethnicities.result[index] = {
        ...state.ethnicities.result[index],
        ...payload.data,
      };
    }
  });

  builder.addCase(deleteEthnicitiesAsync.fulfilled, (state, action) => {
    const payload = action.payload as unknown as { data: string };
    if (state.ethnicities) {
      const index = state.ethnicities.result.findIndex(
        (ethnie) => ethnie._id === payload.data
      );

      state.ethnicities.result.splice(index, 1);

      state.ethnicities.count--;
    }

    Toastify(`l'ethnie a été supprimé avec succès.`, "success", 3000);
  });

  builder.addCase(getBodyShapesAsync.fulfilled, (state, action) => {
    if (!action.payload) return;

    state.bodyShapes = (action.payload as any).data;
  });

  builder.addCase(createBodyShapeAsync.fulfilled, (state, action) => {
    if (state.bodyShapes) {
      state.bodyShapes.result.push(action.payload.data!);
      state.bodyShapes.count++;
    }
  });

  builder.addCase(updateBodyShapeAsync.fulfilled, (state, action) => {
    const payload = action.payload as unknown as { data: TBodyShapes };
    if (state.bodyShapes) {
      const index = state.bodyShapes.result.findIndex(
        (ethnie) => ethnie._id === payload.data._id
      );

      state.bodyShapes.result[index] = {
        ...state.bodyShapes.result[index],
        ...payload.data,
      };
    }
  });

  builder.addCase(deleteBodyShapeAsync.fulfilled, (state, action) => {
    const payload = action.payload as unknown as { data: string };
    if (state.bodyShapes) {
      const index = state.bodyShapes.result.findIndex(
        (bodyShape) => bodyShape._id === payload.data
      );

      state.bodyShapes.result.splice(index, 1);

      state.bodyShapes.count--;
    }

    Toastify(`l'ethnie a été supprimé avec succès.`, "success", 3000);
  });

  builder.addCase(getBusinessHoursAsync.fulfilled, (state, action) => {
    const payload: IApiGet<IAPIGetBusinessHours> = action.payload as any;
    state.businessHours = payload.data;
  });

  builder.addCase(updateBusinessHoursAsync.fulfilled, (state, action) => {
    const payload = action.payload as any;
    if (state.businessHours) {
      const index = state.businessHours.result.findIndex(
        (data) => data._id === payload.data._id
      );

      let data = state.businessHours.result;
      data[index] = { ...payload.data };

      if (index) {
        state.businessHours.result = data;
      }
    }
  });

  builder.addCase(getPlansAsync.fulfilled, (state, action) => {
    state.plans = action.payload;
  });

  builder.addCase(createPlansAsync.fulfilled, (state, action) => {
    if (state.plans) {
      state.plans.data.result.push(action.payload.data);
    }
  });

  builder.addCase(updatePlansAsync.fulfilled, (state, action) => {
    if (state.plans) {
      const index = state.plans.data.result.findIndex(
        (plan) => plan._id === action.payload.data._id
      );

      state.plans.data.result[index] = {
        ...state.plans.data.result[index],
        ...action.payload.data,
      };
    }
  });

  builder.addCase(togglePlanActivenessAsync.fulfilled, (state, action) => {
    if (state.plans) {
      const index = state.plans.data.result.findIndex(
        (plan) => plan._id === action.meta.arg.id
      );

      state.plans.data.result[index].isActive = action.payload.data.isActive;
    }
  });

  builder.addCase(getPaidServicesAsync.fulfilled, (state, action) => {
    state.paidServices = action.payload;
  });

  builder.addCase(createPaidServiceAsync.fulfilled, (state, action) => {
    if (state.paidServices) {
      state.paidServices.data.result.push(action.payload.data);
    }
  });

  builder.addCase(updatePaidServiceAsync.fulfilled, (state, action) => {
    if (state.paidServices) {
      const index = state.paidServices.data.result.findIndex(
        (paidService) => paidService._id === action.payload.data._id
      );

      state.paidServices.data.result[index] = {
        ...state.paidServices.data.result[index],
        ...action.payload.data,
      };
    }
  });

  builder.addCase(togglePaidServiceAsync.fulfilled, (state, action) => {
    if (state.paidServices) {
      const index = state.paidServices.data.result.findIndex(
        (paidService) => paidService._id === action.meta.arg.id
      );

      state.paidServices.data.result[index].isActive =
        action.payload.data.isActive;
    }
  });

  builder.addCase(getAppSettingsAsync.fulfilled, (state, action) => {
    const payload: IApiGet<TAppInterfaceSettings> = action.payload as any;
    state.appInterface = payload.data;
  });

  builder.addCase(getAppSettingsAsync.rejected, (state) => {
    state.appInterface = null;
  });

  builder.addCase(initAppSettingsAsync.fulfilled, (state, action) => {
    state.appInterface = null;
  });

  builder.addCase(updateAppSettingsAsync.fulfilled, (state, action) => {
    state.appInterface = action.payload;
  });

  builder.addCase(getCGUAsync.fulfilled, (state, action) => {
    const payload: IApiGet<IAPIGetCGU> = action.payload as any;
    const contentState = JSON.parse(payload.data.content);

    state.cgu = {
      _id: payload.data._id,
      content: contentState,
    };
  });

  builder.addCase(getCGUAsync.rejected, (state) => {
    state.cgu = { _id: "", content: { blocks: [] } };
  });

  builder.addCase(initCGUAsync.fulfilled, (state, action) => {
    const contentState = JSON.parse(action.payload.data.content);

    state.cgu = {
      _id: action.payload.data._id,
      content: contentState,
    };
  });

  builder.addCase(updateCGUAsync.fulfilled, (state, action) => {
    const newPayload: IApiGet<IApiGet<IAPIPatchCGU>> = action.payload as any;
    const contentState = JSON.parse(newPayload.data.data.content);

    state.cgu = {
      _id: newPayload.data.data._id,
      content: contentState,
    };
  });
};

export default adminAsyncActions;

import { PayloadAction } from "@reduxjs/toolkit";
import { IAppInitialState } from "./app.interfaces";

export const setItemsPerPage = (
  state: IAppInitialState,
  action: PayloadAction<number>
) => {
  state.itemsPerPage = action.payload;
};

export const toggleAvailability = (state: IAppInitialState) => {
  state.isAvailable = !state.isAvailable;
};

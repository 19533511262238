import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectAuth = (state: TRootState) => state.auth;

export const selectAuthErrors = createSelector(
  [selectAuth],
  (auth) => auth.errors
);

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  (auth) => !!auth.user
);

export const selectAuthToken = createSelector(
  [selectAuth],
  (auth) => auth.token
);

export const selectAuthUser = createSelector([selectAuth], (auth) => auth.user);

import { axios } from "@/lib/axios";
import {
  IAPIPatchBodyShape,
  IAPIPostBodyShape,
  IAPIResponseGetBodyShape,
} from "../../types/interfaces/api/bodyShape";

export const getBodyShapes = (): Promise<IAPIResponseGetBodyShape> => {
  return axios.get("/param-s/api/v1/parameters/fatness");
};

export const createBodyShape = ({
  name,
}: {
  name: string;
}): Promise<IAPIPostBodyShape> => {
  return axios.post("/param-s/api/v1/parameters/fatness", {
    name,
  });
};

export const updateBodyShape = ({
  _id,
  name,
}: IAPIPatchBodyShape): Promise<IAPIPatchBodyShape> => {
  return axios.patch(`/param-s/api/v1/parameters/fatness/${_id}`, {
    name,
  });
};

export const deleteBodyShape = ({
  _id,
}: Pick<IAPIPatchBodyShape, "_id">): Promise<IAPIPatchBodyShape> => {
  return axios.delete(`/param-s/api/v1/parameters/fatness/${_id}`);
};

import { createSlice } from "@reduxjs/toolkit";

import { IAppInitialState } from "./app.interfaces";

import * as appAction from "./app.actions";

const initialState: IAppInitialState = {
  itemsPerPage: 5,
  isAvailable: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    ...appAction,
  },
});

export const appSliceActions = appSlice.actions;

export default appSlice.reducer;

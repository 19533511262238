import { Navigate, RouteObject } from "react-router-dom";

import { lazyImport } from "../utils/lazyImport";

import { routePaths } from "../config";

const { AuthRoutes } = lazyImport(
  () => import("../features/auth"),
  "AuthRoutes"
);

export const publicRoutes: RouteObject[] = [
  {
    path: routePaths.auth + "/*",
    element: <AuthRoutes />,
  },
  {
    path: "*",
    element: (
      <Navigate to={`${routePaths.auth}${routePaths.authChildren.signIn}`} />
    ),
  },
];

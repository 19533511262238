export const datepickerLocaleFr = {
  format: "DD/MM/YYYY",
  separator: " - ",
  applyLabel: "Valider",
  cancelLabel: "Annuler",
  fromLabel: "De",
  toLabel: "à",
  customRangeLabel: "Personnaliser",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};

import { TThemeColors } from "../utils/convertThemeColorsToRootColors";

export const colors: TThemeColors = {
  ui: {
    primary: "#73103E",
    secondary: "#FEF8EC",
    tertiary: "#DEB86B",
    dark: "#252525",
    gray: "#8790a1",
    medium: "#363853",
    light: "#F9F9F9",
    black: "#000000",
    white: "#FFFFFF",
    success: "#75D873",
    error: "#872D2D",
    warning: "#F5B544",
  },
  text: {
    primary: "#303448",
    secondary: "rgba(48, 52, 72, 0.67)",
    muted: "#A1A8BC",
    error: "#D0421B",
    success: "#138000",
  },
};
